import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';

export class CustomValidators {

  static password(control: AbstractControl): ValidationErrors | null {
    let error: string | null = null;

    const value: string = control.value as string;

    if (!value || value.length < 10) {
      error = 'Minimum Password length is 10';
    } else if (!/.*[a-z].*/.test(value)) {
      error = 'Password should contain at least 1 lowercase letter';
    } else if (!/.*[A-Z].*/.test(value)) {
      error = 'Password should contain at least 1 uppercase letter';
    } else if (!/.*[0-9].*/.test(value)) {
      error = 'Password should contain at least 1 number';
    } else if (!/.*[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~].*/.test(value)) {
      error = 'Password should contain at least 1 special character';
    } else if (/^[\s]+.*/.test(value)) {
      error = 'Password should not start with whitespaces or empty characters';
    } else if (/.*[\s]+$/.test(value)) {
      error = 'Password should not end with  whitespaces or empty characters';
    }

    return !error ? null : {
      password: {
        error
      }
    };
  }

  static email(control: AbstractControl): ValidationErrors | null {
    let error: string | null = null;

    const value: string = control.value as string;

    if (value) {
      if (!/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/.test(value)) {
        error = 'Email address is not valid';
      }
    }

    return !error ? null : {
      email: {
        error
      }
    };
  }

  static username(control: AbstractControl): ValidationErrors | null {
    let error: string | null = null;

    const value: string = control.value as string;

    if (value) {
      if (!/^[a-zA-Z\\.-]+$/.test(value)) {
        error = 'Username has invalid characters';
      }
    }

    return !error ? null : {
      username: {
        error
      }
    };
  }

  static passwordsMatch(group: FormGroup) { // here we have the 'passwords' group
    const password = group.get('password');
    const pass = password ? password.value as string : null;

    const confirmPassword = group.get('confirmPass');
    const confirmPass = confirmPassword ? confirmPassword.value as string : null;

    return pass === confirmPass ? null : {mismatch: true};
  }
}

abstract class CustomErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null): boolean {
    return (control != null && control.invalid && control.dirty)
      || (
        control != null
        && control.parent != null
        && control.parent.hasError(this.errorCode)
        && control.parent.dirty
      );
  }

  abstract get errorCode(): string;
}

export class MismatchErrorStateMatcher extends CustomErrorStateMatcher {
  get errorCode(): string {
    return 'mismatch';
  }
}
